import React from 'react'


const Contact = () => {
  return (
    <div name='contact' className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4 '>
      <form method='POST' action="https://getform.io/f/f3f02050-157f-438b-bf6d-4b956f9710b8" className='flex flex-col max-w-[600px] w-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300'>Contact</p>
          <div className="flex items-center">
          <p className="text-gray-300 py-4 mr-2">
          Submit the form below or shoot me an
          </p>
          <a href="mailto:ivo.blumbergs@gmail.com" className="text-pink-500">Email</a>
          </div>
          
        </div>
        <input className='bg-[#ccd6f6] p-2' type="text" placeholder='Name' name="name"/>
        <input className='my-4 p-2 bg-[#ccd6f6]' type="email" name="email" placeholder='Email'/>
        <textarea className='bg-[#ccd6f6] p-2' name="message" rows="10" placeholder='Message'></textarea>
        <button className='text-white border-2 hover:bg-pink-600 hover:border-pink-600 px-4 py-3 my-8 mx-auto flex items-center'>Let's Collaborate</button>
      </form>
    </div>
  )
}

export default Contact
