import React from 'react';

const About = () => {
  return (
    <div name='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-pink-600'>About</p>
          </div>
          <div></div>
        </div>
        <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
          <div  className='sm:text-right text-4xl font-bold'>
            <p>Hi, I'm Ivo, nice to meet you. Please take a look around.</p>
          </div>
          <div>
            <p className='text-[#8892b0] py-4 max-w-[700px]'>I am a passionate web developer and explorer of digital possibilities,
            I find joy in crafting beautiful and user-friendly web applications that leave a positive impact.
            Ever since I wrote my first line of code, I knew I had found my calling. Web development allows me to blend creativity with problem-solving,
            making each project a thrilling journey of discovery.
            When I'm not immersed in code, you'll find me exploring the great outdoors. Hiking is my escape, 
            a chance to connect with nature's wonders and recharge my creative energy.

            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
